import React from "react";

import "./style.css";
import type { CardEventProps } from "../../_types/CardEvent";

export function CardEvent({
  name,
  status,
  onClick,
}: CardEventProps): JSX.Element {
  const statusCard = `event__${status}`;
  const labelStatus = status === "success" ? "sucesso" : "erro";

  return (
    <div className={`container__event ${statusCard}`}>
      <div>
        <p className="event__text">
          <strong>Evento: </strong> {name}
        </p>
        <p className="event__text">
          <strong>Status: </strong> {labelStatus}
        </p>
      </div>
      <button type="button" className="event__button" onClick={onClick}>
        Detalhes
      </button>
    </div>
  );
}
