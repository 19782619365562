import React from 'react';
import { FieldValidation } from '@rjsf/utils';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';


/*
 This is the actual component that will get rendered in the form
*/

export const ValidateKebabCase = ({
  onChange,
  rawErrors=[],
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <InputLabel htmlFor="validateName">Nome</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        onChange={e => onChange(replaceDomain(e.target?.value))}
      />
      <FormHelperText id="entityName">
      Use apenas letras, números, hífen e sublinhado
      </FormHelperText>
    </FormControl>
    
  );
}

function replaceDomain(value: string){
  // return value.replace(/^domain:default\//, "");
  return value.replace(/^domain-*\/_/, "");
}

/*
 This is a validation function that will run when the form is submitted.
  You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\
*/

export const validateKebabCaseValidation = (
  value: string,
  validation: FieldValidation
) => {
  const kebabCase = /^[a-z0-9-_]+$/g.test(value);
  const LenghtCase = value? value : '';
  const maxLengh = 60;
  const minLengh = 2;

  if (kebabCase === false) {
    validation.addError(
      `Use apenas letras, números, hífen ("-") e sublinhado ("_")..`,
    );
  } else if (LenghtCase.length < minLengh || LenghtCase.length > maxLengh) {
    validation.addError(
      `O tamanho do nome deve ter o mínimo de ${minLengh} e máximo de ${maxLengh} caracteres.`,
    );
  }
};


