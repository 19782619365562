import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import { RDTechRadarClient } from './lib/RDTechRadarClient';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';

// import { costInsightsApiRef, ExampleCostInsightsClient } from '@backstage/plugin-cost-insights';
// import { CostInsightsClient } from './lib/CostInsightsClient';


export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory(techRadarApiRef, new RDTechRadarClient()),
  // createApiFactory({
  //   api: costInsightsApiRef,
  //   deps: {},
  //   factory: () => new CostInsightsClient(),
  //   // factory: () => new ExampleCostInsightsClient(),
  // }),
];
