import { Content, Header, Page } from '@backstage/core-components';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { HomePageStarredEntities } from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { QuestionTableCard } from '@drodil/backstage-plugin-qeta';
import { NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import LogoHome from './LogoHome';
import { Toolkit } from './Toolkit';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '10px',
    margin: 'auto',
  },
}));


export function getGreeting() {
  const today = new Date()
  const currentTime = today.getHours()

  if (currentTime < 12) {
    return 'Good morning'
  }
  else if (currentTime < 18) {
    return 'Good afternoon'
  }

  return 'Good evening'
};

export const HomePage = () => {
  const classes = useStyles();
  const greeting = getGreeting();
  const identityApi = useApi(identityApiRef);

  const { value: profile, error } = useAsync(() =>
    identityApi.getProfileInfo(),
  );

  const welcomeTitle = !error ? `${greeting}${profile?.displayName ? `, ${profile?.displayName}` : ''}!` : `${greeting}!`

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center" spacing={3}>
            <LogoHome />
            <Grid container item xs={12}>
              <Grid item md={12}>
                <NewAnnouncementBanner />
              </Grid>
              <Grid item md={12}>
                <HomePageSearchBar
                  classes={{ root: classes.searchBar }}
                  placeholder="Pesquisar"
                  style={{ marginBottom: '20px' }}
                />
              </Grid>
              <Grid item md={12}>
                <Header title={welcomeTitle} pageTitleOverride="Home" style={{ background: 'linear-gradient(#75a189, #7aa78e)', marginBottom: '10px', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }} />
              </Grid>
              <Grid item md={6}>
                <Toolkit />
              </Grid>
              <Grid item md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item md={12}>
                <QuestionTableCard
                  title='Perguntas Frequentes'
                  rowsPerPage={5}
                  quickFilter='most_viewed'
                />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
