import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { parseEntityRef } from '@backstage/catalog-model';

export interface GetLoggedInUserUiOptions {
  readonly?: boolean;
}

export const GetLoggedInUser = (
  props: FieldExtensionComponentProps<string, GetLoggedInUserUiOptions>,
) => {
  const identityApi = useApi(identityApiRef);
  const {
    onChange,
    schema: { title = 'Logged User', description = 'User based on the identity provider' },
    rawErrors,
    required,
    uiSchema: {'ui:autofocus': autoFocus},
    formData
  } = props;

  useAsync(async () => {
    const identity = await identityApi.getBackstageIdentity();
    const { name: userId } = parseEntityRef(identity.userEntityRef);
    const profile = await identityApi.getProfileInfo();
    onChange(`${profile.displayName} <${profile.email}> [${userId}]`);
  });


  return (
    
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <TextField 
      	id="outlined-basic"
        label={title}
        variant="outlined"
        inputProps={{ autoFocus }} 
        helperText={description}
        value={formData ?? ''}
        required={required}
        disabled
        onChange={({ target: { value } }) => onChange(value)}
	   />
    </FormControl>
  );
};
