import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { GitLabDomainPicker } from './GitLabDomainPicker';

export const GitLabDomainPickerField = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GitLabDomainPicker',
    component: GitLabDomainPicker,
  }),
);
