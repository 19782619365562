import React, { useEffect } from 'react';
import { useFormStatusContext } from '../components/Contexts/FormStatus';
import { useNavigate } from 'react-router-dom';
import type {
  UseHomeReturn,
  OnSubmitProps,
  NameFlags,
  NamePlatform,
  NameEnvironment,
  OnChangeInputDataProps,
} from '../_types/Home';
import { checkStatusEvents } from '../services/statusEvent';

export function useHome(): UseHomeReturn {
  const { dispatch, actionsFormStatus, stateFormStatus } =
    useFormStatusContext();
  const { setPayloadStatus } = actionsFormStatus;
  const navigate = useNavigate();

  const [inputData, setInputData] = React.useState<{
    flagSelected: NameFlags;
    environmentSelected: NameEnvironment;
    platformSelected: NamePlatform;
  }>({
    flagSelected: 'none',
    environmentSelected: 'none',
    platformSelected: 'none',
  });

  const [eventsSelected, setEventsSelected] = React.useState<string[]>([]);

  const [emptyFields, setEmptyFields] = React.useState<string[]>([]);

  const [isOpenAlert, setIsOpenAlert] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const isWEB = inputData.platformSelected === 'web';

  function onChangeInputData({ key, value }: OnChangeInputDataProps): void {
    const isDifferentCurrent = value !== inputData[key];
    setInputData({ ...inputData, [key]: isDifferentCurrent ? value : 'none' });
  }

  function isSelectedEvent(value: string): boolean {
    const eventExistInSelected = eventsSelected.some(item => item === value);
    if (eventExistInSelected) {
      return true;
    }
    return false;
  }

  function onChangeEventsSelected(value: string): void {
    const eventExistInSelected = eventsSelected.some(item => item === value);
    let updateEventsSelected = eventsSelected;
    if (eventExistInSelected) {
      updateEventsSelected = eventsSelected.filter(event => event !== value);
    } else {
      updateEventsSelected = [...eventsSelected, value];
    }
    setEventsSelected(updateEventsSelected);
  }

  function getFormattedDataFromWEB(inputValue: string): string {
    const result = inputValue.split(';').map(item => item.trim());
    return result.join(';');
  }

  function getFormattedDataFromMobile(inputValue: string): string {
    const result = JSON.parse(inputValue).map((item: { traceId?: string }) => {
      const isValidString = typeof item?.traceId === 'string';
      return isValidString ? item?.traceId : '';
    });
    return result.join(';');
  }

  function getUrlFromEnvironment(): string {
    const mapURL = {
      prod: 'https://bff.drogaraia.com.br/graphql',
      stg: 'https://bff.stage2.drogaraia.com.br/graphql',
      qa: 'https://bff.qa2.drogaraia.com.br/graphql',
      none: '',
    };

    const urlEnvironment = mapURL[inputData.environmentSelected];
    return urlEnvironment;
  }

  async function handleResultsValidationMS(inputValue: string) {
    try {
      setIsLoading(true);
      const traceIds = isWEB
        ? getFormattedDataFromWEB(inputValue).replace(/["']/g, '')
        : getFormattedDataFromMobile(inputValue);

      const urlEnvironment = getUrlFromEnvironment();
      const response = await checkStatusEvents({
        traceIds,
        url: urlEnvironment,
      });
      const dataResponse = JSON.parse(response);

      const errors = dataResponse?.errors ?? [];

      if (errors?.length > 0) {
        setEmptyFields(
          errors.map((item: { message?: string }) => item?.message ?? ''),
        );
        setIsOpenAlert(true);
        return;
      }

      const events = dataResponse?.data?.getTagifyEventsStatus?.events;

      const payload = {
        flag: inputData.flagSelected,
        platform: inputData.platformSelected,
        events: eventsSelected,
        environment: inputData.environmentSelected,
        input: JSON.stringify(events),
        amountTraceIdInformed: traceIds.split(`;`)?.length ?? 0,
      };

      dispatch(setPayloadStatus(payload));
      navigate('/tagify/results');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  function checkIsValidInputMobile(value: any): boolean {
    try {
      const valueString = value;
      const isValidArray = Array.isArray(JSON.parse(valueString));
      return isValidArray;
    } catch (e) {
      return false;
    }
  }

  function checkIsValidInputWEB(value: any): boolean {
    try {
      const valueString = value;
      const isValidArray = Array.isArray(JSON.parse(valueString));
      return !isValidArray;
    } catch (e) {
      const isValidValue = typeof value === 'string' || value !== '';
      return isValidValue;
    }
  }

  function formValidation(input: any): boolean {
    const invalidFields = [];
    const valueInput = input?.trim();
    const isInvalidInput = typeof valueInput !== 'string' || valueInput === '';

    if (inputData.flagSelected === 'none')
      invalidFields.push('A bandeira não foi selecionada!');

    if (inputData.platformSelected === 'none')
      invalidFields.push('A plataforma não foi selecionada!');

    if (inputData.environmentSelected === 'none')
      invalidFields.push('O ambiente não foi selecionado!');

    if (isInvalidInput)
      invalidFields.push('TraceId ou resultado não informado!');

    if (isWEB) {
      const isInvalidInputWEB = !checkIsValidInputWEB(valueInput);
      if (isInvalidInputWEB)
        invalidFields.push('Formato de dados inválido para platarformas WEB');
    } else {
      const isInvalidInputWEB = !checkIsValidInputMobile(valueInput);
      if (isInvalidInputWEB)
        invalidFields.push(
          'Formato de dados inválido para platarformas MOBILE!',
        );
    }

    const isValidInputInformations = invalidFields.length === 0;

    if (!isValidInputInformations) {
      setEmptyFields(invalidFields);
      setIsOpenAlert(true);
    }

    return isValidInputInformations;
  }

  function onSubmit(event: OnSubmitProps): void {
    event.preventDefault();

    const isValidInputInformations = formValidation(
      event.target.elements?.input?.value,
    );

    if (isValidInputInformations) {
      handleResultsValidationMS(event.target.elements?.input?.value);
    }
  }

  useEffect(() => {
    const isValidData = stateFormStatus !== null;
    if (isValidData) {
      dispatch(setPayloadStatus(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isSelectedEvent,
    onChangeEventsSelected,
    onSubmit,
    onCloseAlert: () => setIsOpenAlert(false),
    onChangeInputData,
    emptyFields,
    isOpenAlert,
    inputData,
    isLoading,
    titleInformation: isWEB
      ? 'Atenção ao formato do dados para plataformas WEB'
      : 'Atenção ao formato do dados para plataformas Mobile',
    exampleInformation: isWEB
      ? '6e881247-467d-4ce1-ac0e-efc00bc4ca55; 0d1c78c7-c3a4-4791-9194-84ab9ae9ff84'
      : [
          {
            event: 'purchase',
            traceId: 'dbe87217-a5d5-400a-8f6c-97fce307b783',
          },
          {
            event: 'add_payment_info',
            traceId: 'df4eaed7-772b-407d-bfe6-be4276a94069',
          },
        ],
    isVisibleInformation: inputData.platformSelected !== 'none',
  };
}
