import React from 'react';
import './style.css';
import type { AlertModalProps } from '../../_types/AlertModal';
import { createPortal } from 'react-dom';

export function AlertModal({
  title,
  content,
  titleButton = 'Entendido',
  onPress,
  isOpen,
}: AlertModalProps): JSX.Element {
  const styleContainer = isOpen ? 'container__open' : 'container__close';

  return createPortal(
    <>
      <div className={`container__alert ${styleContainer}`}>
        <div className="alert__box">
          <img src="../assets/images/alert.png" alt="Logo da Tagify" />
          <p className="box__title">{title}</p>
          {content}
          <button type="button" className="box__button" onClick={onPress}>
            {titleButton}
          </button>
        </div>
      </div>
    </>,
    document.body,
  );
}
