import React from "react";
import "./style.css";
import type { LabelEventProps } from "../../_types/LabelEvent";

export function LabelEvent({
  onSelect,
  name,
  isSelected = false,
  isDisabled,
  variant = "normal",
}: LabelEventProps): JSX.Element {
  const statusEvent = isSelected ? "event__selected" : "event__deselect";
  const styleVariant = `event__${variant}`;
  return (
    <button
      type="button"
      className={`container__label ${statusEvent} ${styleVariant}`}
      onClick={onSelect}
      disabled={isDisabled}
    >
      {name.toUpperCase()}
    </button>
  );
}
