import { makeStyles, FormControl, InputLabel, Input, FormHelperText, Select, MenuItem, CircularProgress, Link, Button } from '@material-ui/core';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import { LinkButton } from '@backstage/core-components';
import React, { useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

interface IForm {
    responsavel: string,
    iniciativa: string,
    premissas: string,
    contexto: string,
    servico: string,
    projeto: string,
    jornada: string,
    tribo: string,
    squad: string,
    dod: string
}

interface IHelperText {
    description?: string,
    title: string,
    id: string
}

interface IDescription {
    version: Number,
    type: String,
    content: Array<String>
}

interface IAttachments {
    anexos: FormData,
    text: string
}

interface IError {
    field: string,
    error: string
}

interface IResponse {
    self: string,
    key: string,
    id: string
}

// Todos os requests abaixo utilizam Basic Auth

// Exemplo de FormData anexo
/* curl --location --request POST 'https://your-domain.atlassian.net/rest/api/3/issue/TEST-123/attachments'
 -u 'email@example.com:<api_token>'
 -H 'X-Atlassian-Token: no-check'
 --form 'file=@"myfile.txt"' */

// no caso de requisições FETCH o BODY é o FormData

 // Exemplo de Link para o POST
 // https://raiadrogasilsa.atlassian.net/rest/api/3/issue/ARQSIS-13/attachments

// Exemplo de Link que pode ser retornado após o 201
// https://raiadrogasilsa.atlassian.net/browse/ARQSIS-13

// Exemplo de RESPONSE 201 CREATED
/* {
    "id": "370103",
    "key": "ARQSIS-13",
    "self": "https://raiadrogasilsa.atlassian.net/rest/api/3/issue/370103"
} */

// Exemplo de BODY POST
/* {
    "fields": {    
      "summary": "[JIRA] Teste de integração Dev Portal", // TÍTULO
      "project": { "id": "10948",  "key": "ARQSIS" }, // PROJETO
      "priority": { "name": "Low", "id": "4" }, // PRIORIDADE
      "issuetype": { "id": "10208" }, // TASK
      "labels": []
    },
    "update": {}
} */

// Exemplo de Headers
/* headers: {
    'Authorization': `Basic ${Buffer.from(
        'email@example.com:'
    ).toString('base64')}`,
    'Accept': 'application/json',
    'X-Atlassian-Token': 'no-check'
} */

const Component = () => {
    const identityApi = useApi(identityApiRef);
    const { value: profile, error } = useAsync(() => identityApi.getProfileInfo());

    const useStyles = makeStyles(() => ({
        form: {
            marginBottom: "30px",
            flexWrap: "wrap",
            display: "flex"
        },
        input: {
            '@media (min-width: 780px)': { width: "50%" },
            boxSizing: "border-box",
            alignSelf: "flex-end",
            maxWidth: "700px",
            padding: "20px",
            width: "100%"
        },
        footer: { display: "flex", alignItems: "center" },
        padding: { paddingLeft: "10px"},
        hidden: { display: "none" }
    }));

    const helperTexts: Array<IHelperText> = [
        { id: "premissas", description: "Refere-se as condições  e/ou expectativa da atuação do arquiteto junto a squad, necessidade de prazo de entrega", title: "Premissas"},
        { id: "iniciativa", description: "Refere-se a proposta de projeto originado em alguma diretoria ou gerencia executiva ( Centro de Custo )", title: "Iniciativa"},
        { id: "projeto", description: "Refere-se ao nome do projeto/serviço/aplicação originado a partir de uma iniciativa atual ou passada", title: "Projeto" },
        { id: "squad", description: "Refere-se ao nome da equipe que está responsável por um produto / serviço que é parte de um projeto", title: "Squad"},
        { id: "contexto", description: "Refere-se a uma explicação sucinta acerca do que a Squad esta atuando no momento", title: "Contexto"},
        { id: "servico", description: "Refere-se a gama de entregáveis possíveis para tal solicitação", title: "Serviço" },
        { id: "tribo", description: "Refere-se a gerência ou coordenação abaixo da gerencia executiva", title: "Tribo" },
        { id: "responsavel", description: "Refere-se ao LT ou PO do produto / serviço", title: "Reponsável"},
        { id: "jornada", description: "Refere-se a gerência executiva", title: "Jornada" },
        { id: "dod", description: "Refere-se ao empregável esperado", title: "DOD" },
        { id: "inception", title: "Inception" },
        { id: "discovery", title: "Discovery" },
        { id: "analyse", title: "Análise" },
        { id: "asis", title: "As-Is" },
        { id: "tobe", title: "To-Be" }
    ];

    const getDescription= ($id: string) => helperTexts.filter(entry => entry.id === $id).pop()?.description || "";
    const getTitle = ($id: string) => helperTexts.filter(entry => entry.id === $id).pop()?.title || "";
    
    const classes = useStyles();

    const [isLoading, setLoading] = useState<Boolean>(false);
    const [errors, setErrors] = useState<IError[]>([]);
    const [form, updateForm] = useState<IForm>({
        responsavel: "",
        iniciativa: "",
        premissas: "",
        contexto: "",
        projeto: "",
        servico: "",
        jornada: "",
        tribo: "",
        squad: "",
        dod: "",
    });

    const [attachments, updateAttachments] = useState<IAttachments>({
        anexos: new FormData(),
        text: ""
    });

    const checkError = (field: string): string => {
        const filtered = errors.filter(($error: IError) => $error.field === field);
        const value = filtered.pop()?.error;
        return value ? value : "";
    }
    
    const [feedback, setFeedback] = useState<string>("");

    useEffect(() => {
        const data = Array.from(attachments.anexos.entries());
        let content = "Anexos ";
        data.forEach((entry, index) => {
            const name = entry.shift();
            content += index >= 1 ?  ` ${name}` : name;
        });

        updateAttachments($attachments => ({...$attachments, text: content }));
    }, [attachments.anexos])

    useEffect(() => {        
        if(profile?.displayName) updateForm($form => ({...$form, responsavel: profile.displayName || ""}));
    },[profile, error]);

    useEffect(() => {
        const possibleOptions = ["inception", "discovery", "analyse", "asis", "tobe"];
        const currentURL = window.location.href.split('?').pop()?.replace("service=", "") || "";
        const matchOptions = possibleOptions.filter(option => option === currentURL);

        if(matchOptions.length >= 1) {
            const option: string = matchOptions.pop() || "";
            updateForm($form => ({...$form, servico: option }));   
        }
    }, []);

    // useEffect(() => console.log(JSON.stringify(Array.from(attachments.anexos.entries()))),[attachments]);
    // useEffect(() => console.log(JSON.stringify(errors)),[errors]);
    // useEffect(() => console.log(JSON.stringify(form)),[form]);

    // Função que verifica o preenchimento de cada campo de texto, de forma que se identificado algum erro ( campo obrigatório )
    // este já é inserido no estado de erros
    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        // console.log(`value :: ${event.target.value}`);
        const id = event.target.id;
        const value = event.target.value;
        
        if(value.trim().length <= 0) setErrors([...errors, { error: "Este campo é obrigatório", field: id }]);
        else setErrors(errors.filter(item => item.field !== id));

        updateForm($form => ({...$form, [id]: value}));
    }

    // Função que atualiza no estado do componente qual tipo de serviço foi selecionado
    const handleService = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        const value: string = event.target.value ? event.target.value.toString() : "";
        updateForm($form => ({...$form, servico: value }));
        checkError("servico");
    }

    const resetForm = () => {
        updateAttachments($attachments => ({...$attachments, anexos: new FormData()}));
        Object.keys(form).forEach(key => {
            if(key !== "responsavel")
                updateForm($form => ({...$form, [key]: ""}));
        });
    }
    
    // Função que realiza o upload do formulário para o Back-end, com os arquivos anexados se existentes
    const handleSubmit = async () => {        
        const description: IDescription = {
            version: 1,
            type: "doc",
            content: []
        }

        Object.entries(form).forEach(entry => {
            const [key, value] = entry;
            const item = JSON.stringify({
                    content: [{ text: `${getTitle(key) || ""} : ${key === "servico" ? getTitle(value) : value}`, type: "text" }],
                    type: "paragraph"
            });

            description.content.push(item);
        });        

        const request = {
            fields: {
                summary: `[JIRA] ${getTitle(form.servico)} ${profile?.displayName}`,
                project: { "key": "ARQSIS" },
                issuetype: { "id": "10208" },
                description: description,
                priority: { "id": "4" }
            }
            /* email: profile?.email */
        }

        setLoading(true);
        await fetch('http://localhost:7000/requests', {
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request),
            method: 'POST'
        })
        .then(response => response.json())
        .then(async (result:IResponse) => {
            setFeedback(`https://raiadrogasilsa.atlassian.net/browse/${result.key}`);
            if(Array.from(attachments.anexos.keys()).length > 0) {
                await fetch(`http://localhost:7000/requests/attach/${result.key}`,{
                    headers: { 'X-Atlassian-Token':'no-check' },
                    body: attachments.anexos,
                    method: 'POST'
                })
                .catch(problem => { throw new Error(`ERRO :: ${problem}`) });
            }
            setLoading(false);
            resetForm();
        })
        .catch(problem => { throw new Error(`ERRO :: ${problem}`) });
    }

    const handleFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.currentTarget.files;
        const formData = new FormData();
        if(files && Array.from(files).length > 0) {
            Array.from(files).forEach(file => formData.append(file.name, file));
            // Array.from(formData.entries()).forEach(entry => console.log(JSON.stringify(entry)));
            updateAttachments($attachments => ({...$attachments, anexos: formData }));
        }
    }    

    // Função destinada a verificar se existe algum campo OBRIGATÓRIO que deixou de ser preenchido
    const checkEmpty = () => {
        return Object.entries(form).filter(entry => {
            const [key, value] = entry;
            
            /* Explicação */
            // Se o serviço selecionado for do tipo To-Be ou Análise todos o campos são considerados ( inclusive o de Iniciativa ),
            // então o retorno verifica se ele está vazio

            // Por outro lado se o serviço for de qualquer outro tipo, como o campo de Iniciativa não existe ele é desconsiderado, por isso
            // utiliza-se o !== para indicar que os únicos a serem considerados são os que estão na tela

            // Lembrando que a função FILTER usa um código condicional / comparativo e retorna uma Booleana, sendo assim o valor final é true ou false

            switch(form.servico) {
                case "tobe":
                case "analyse":
                    return value === ""
                default:
                    return key !== "iniciativa" ? value === "" : false
            }
        }).length > 0;
    }

    return (
        <>
            <div className={classes.form}>
                <div className={classes.input}>
                    <FormControl disabled={!!isLoading} fullWidth error={!!checkError("servico")}>
                        <InputLabel htmlFor="servico">* Tipo de Serviço</InputLabel>
                        <Select labelId="servico" id="servico" value={form.servico} onChange={handleService} label="Serviço">
                            {/* <MenuItem value=""> <em>Nenhum</em> </MenuItem> */}
                            <MenuItem value="inception"> Inception </MenuItem>
                            <MenuItem value="discovery"> Discovery </MenuItem>
                            <MenuItem value="analyse"> Análise </MenuItem>
                            <MenuItem value="asis"> As-Is </MenuItem>
                            <MenuItem value="tobe"> To-Be </MenuItem>
                        </Select>
                        <FormHelperText id="servico">{checkError("servico") || getDescription("servico") || ""}</FormHelperText>
                    </FormControl>
                </div>

                <div className={form.servico === "tobe" || form.servico === "analyse" ? classes.input : classes.hidden}>
                    <FormControl disabled={!!isLoading} fullWidth error={!!checkError("iniciativa")}>
                        <InputLabel htmlFor="iniciativa">* Iniciativa</InputLabel>
                        <Input value={form.iniciativa} onChange={handleInput} multiline maxRows={4} id="iniciativa"/>
                        <FormHelperText id="iniciativa">{checkError("iniciativa") || getDescription("iniciativa") || ""}</FormHelperText>
                    </FormControl>
                </div>

                <div className={classes.input}>
                    <FormControl disabled={!!isLoading} fullWidth error={!!checkError("projeto")}>
                        <InputLabel htmlFor="projeto">* Projeto</InputLabel>
                        <Input value={form.projeto} onChange={handleInput} multiline maxRows={4} id="projeto"/>
                        <FormHelperText id="projeto">{checkError("projeto") || getDescription("projeto") || ""}</FormHelperText>
                    </FormControl>
                </div>

                <div className={classes.input}>
                    <FormControl disabled={!!isLoading} fullWidth error={!!checkError("jornada")}>
                        <InputLabel htmlFor="jornada">* Jornada</InputLabel>
                        <Input value={form.jornada} onChange={handleInput} multiline maxRows={4} id="jornada"/>
                        <FormHelperText id="jornada">{checkError("jornada") || getDescription("jornada") || ""}</FormHelperText>
                    </FormControl>
                </div>

                <div className={classes.input}>
                    <FormControl disabled={!!isLoading} fullWidth error={!!checkError("tribo")}>
                        <InputLabel htmlFor="tribo">* Tribo</InputLabel>
                        <Input value={form.tribo} onChange={handleInput} multiline maxRows={4} id="tribo"/>
                        <FormHelperText id="tribo">{checkError("tribo") || getDescription("tribo") || ""}</FormHelperText>
                    </FormControl>
                </div>

                <div className={classes.input}>
                    <FormControl disabled={!!isLoading} fullWidth error={!!checkError("squad")}>
                        <InputLabel htmlFor="squad">* Squad</InputLabel>
                        <Input value={form.squad} onChange={handleInput} multiline maxRows={4} id="squad"/>
                        <FormHelperText id="squad">{checkError("squad") || getDescription("squad") || ""}</FormHelperText>
                    </FormControl>
                </div>

                <div className={classes.input}>
                    <FormControl disabled fullWidth error={!!checkError("responsavel")}>
                        <InputLabel htmlFor="responsavel">* Responsável</InputLabel>
                        <Input value={form.responsavel} onChange={handleInput} multiline maxRows={4} id="responsavel"/>
                        <FormHelperText id="responsavel">{checkError("responsavel") || getDescription("responsavel") || ""}</FormHelperText>
                    </FormControl>
                </div>

                <div className={classes.input}>
                    <FormControl disabled={!!isLoading} fullWidth error={!!checkError("contexto")}>
                        <InputLabel htmlFor="contexto">* Contexto</InputLabel>
                        <Input value={form.contexto} onChange={handleInput} multiline maxRows={4} id="contexto"/>
                        <FormHelperText id="contexto">{checkError("contexto") || getDescription("contexto") || ""}</FormHelperText>
                    </FormControl>
                </div>

                <div className={classes.input}>
                    <FormControl disabled={!!isLoading} fullWidth error={!!checkError("dod")}>
                        <InputLabel htmlFor="dod">* DOD</InputLabel>
                        <Input value={form.dod} onChange={handleInput} multiline maxRows={4} id="dod"/>
                        <FormHelperText id="dod">{checkError("dod") || getDescription("dod") || ""}</FormHelperText>
                    </FormControl>
                </div>

                <div className={classes.input}>
                    <FormControl disabled={!!isLoading} fullWidth error={!!checkError("premissas")}>
                        <InputLabel htmlFor="premissas">* Premissas</InputLabel>
                        <Input value={form.premissas} onChange={handleInput} multiline maxRows={4} id="premissas"/>
                        <FormHelperText id="premissas">{checkError("premissas") || getDescription("premissas") || ""}</FormHelperText>
                    </FormControl>
                </div>

                <div className={classes.input}>
                    <FormControl>
                        <input onChange={handleFiles} accept="*" className={classes.input} style={{ display: 'none' }} id="outlined-button-file" multiple type="file" />
                        <label htmlFor="outlined-button-file">
                            <Button variant='outlined' color='primary' component="span">Inserir Anexos</Button>
                        </label>
                    </FormControl>
                </div>
            </div>

            <div className={classes.footer}>
                <LinkButton disabled={errors.length > 0 || !!checkEmpty() || !!isLoading} to="#" color="primary" onClick={handleSubmit}> SOLICITAR </LinkButton>
                <CircularProgress size={20} className={isLoading ? "" : classes.hidden} />
                <Link className={isLoading || feedback.indexOf("ERRO") >= 0 ? classes.hidden : ""} target='_blank' href={ feedback }> { feedback } </Link>
                <FormHelperText error className={feedback.indexOf("ERRO") >= 0 && !isLoading ? classes.padding : classes.hidden}>{ feedback }</FormHelperText>
                <FormHelperText className={Array.from(attachments.anexos.entries()).length > 0 ? classes.padding : classes.hidden}> { attachments.text } </FormHelperText>
            </div>
        </>
    )
}

export { Component };