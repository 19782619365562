import {
    TechRadarApi,
    TechRadarLoaderResponse,
  } from '@backstage-community/plugin-tech-radar';
import json from './data.json'
  
export class RDTechRadarClient implements TechRadarApi {
    async load(_id: string | undefined): Promise<TechRadarLoaderResponse> {
        // if needed id prop can be used to fetch the correct data

        const data = json;
        // For example, this converts the timeline dates into date objects
        return {
        ...data,
        entries: data.entries.map((entry) => ({
            ...entry,
            timeline: entry.timeline.map(timeline => ({
            ...timeline,
            date: new Date(timeline.date),
            })),
        })),
        quadrants: data.quadrants,
        rings: data.rings
        };
    }
}