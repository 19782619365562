import React from 'react';

import { TagifyHome } from './home/index';
import { ResultSearch } from './results';

const Home = () => {
    return (
      <TagifyHome />
    );
};

const Results = () => {
    return (
      <ResultSearch />      
    )
}

export { Home, Results };