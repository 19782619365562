import { ActionsTypes } from "../../../_types/FormStatus";

export const FormStatusReducer = (state: any, action: any): any => {
  switch (action.type) {
    case ActionsTypes.setPayloadStatus: {
      return { ...action.payload };
    }
    default:
      return {
        ...state,
      };
  }
};
