import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { HttpPicker } from './HttpPicker';

export const HttpPickerField = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'HttpPicker',
    // @ts-ignore
    component: HttpPicker,
  }),
);
