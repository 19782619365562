import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { GetLoggedInUser } from './GetLoggedInUser';

export const GetLoggedInUserField = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GetLoggedInUser',
    component: GetLoggedInUser,
  }),
);
