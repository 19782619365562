import React from "react";
import { FormStatusReducer } from "./reducer";
import { FormStatusActions } from "./actions";
import type {
  FormStatusProps,
  UseFormStatusContextReturn,
} from "../../../_types/FormStatus";

const initialState = null;

const FormStatusContext = React.createContext<any>(initialState);

function FormStatusProvider({ children }: FormStatusProps): JSX.Element {
  const [state, dispatch] = React.useReducer(FormStatusReducer, initialState);

  return (
    <FormStatusContext.Provider
      value={{ state, actions: FormStatusActions, dispatch }}
    >
      {children}
    </FormStatusContext.Provider>
  );
}

function useFormStatusContext(): UseFormStatusContextReturn {
  const { dispatch, state, actions } = React.useContext(FormStatusContext);

  return {
    dispatch,
    stateFormStatus: state,
    actionsFormStatus: actions,
  };
}

export { FormStatusContext, FormStatusProvider, useFormStatusContext };