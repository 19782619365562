import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { HttpPickerKey } from './HttpPickerKey';

export const HttpPickerKeyField = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'HttpPickerKey',
    // @ts-ignore
    component: HttpPickerKey,
  }),
);
