import React from 'react';
import './style.css';
import { type HeaderProps } from '../../../../_types/DetailsEventsModal';

export function Header({
  statusFront,
  statusMS,
  nameEvent,
  traceId,
  isSuccessEvent,
  onClose,
  onCopyDataClipboard,
}: HeaderProps): JSX.Element {
  const containerStyle = `container__${statusFront}`;
  return (
    <div className={`container__header ${containerStyle}`}>
      <div className="header__informations">
        <p>
          <strong> Evento:</strong> {nameEvent}
        </p>
        <p>
          <strong> Status:</strong> {statusMS}
        </p>
        <p>
          <strong> TraceID:</strong> {traceId}
        </p>
      </div>
      <div className="header__buttons">
        {isSuccessEvent && (
          <button className="header__button" onClick={onCopyDataClipboard}>
            copiar
          </button>
        )}
        <button className="header__button" onClick={onClose}>
          fechar
        </button>
      </div>
    </div>
  );
}
