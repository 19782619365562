import React from 'react';
import './style.css';
import { useHome } from './useHome';
import {
  optionsEvents,
  optionsFlags,
  optionsPlatform,
  optionsEnvironment,
} from '../utils/formStatusOptions';
import {
  AlertModal,
  CardOption,
  FullLoading,
  Information,
  LabelEvent,
} from '../components';

export function TagifyHome(): JSX.Element {

  const {
    isSelectedEvent,
    onChangeEventsSelected,
    onSubmit,
    onCloseAlert,
    onChangeInputData,
    emptyFields,
    isOpenAlert,
    inputData,
    isLoading,
    titleInformation,
    exampleInformation,
    isVisibleInformation,
  } = useHome();

  return (
    <React.Fragment>
      <FullLoading
        title="Buscando informações do evento"
        isLoading={isLoading}
      />
      <section className="container__home">
        <AlertModal
          title="Ops! informação pendente"
          isOpen={isOpenAlert}
          onPress={onCloseAlert}
          content={
            <div className="messages">
              {emptyFields.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          }
        />
        <header className="home__header">
          <img src="../assets/images/logo_tagify.png" alt="Logo do Tagify" />
        </header>
        <h1 className="home__title">Validação de status</h1>
        <section className="home__options">
          <div className="options__group">
            <p className="options__title">Selecione a bandeira</p>
            <div className="options__container">
              {optionsFlags.map(({ name, label, image }) => (
                <CardOption
                  key={name}
                  label={label}
                  isSelected={inputData.flagSelected === name}
                  image={image.file}
                  size={{ w: image.w, h: image.h }}
                  onSelect={() => {
                    onChangeInputData({ key: 'flagSelected', value: name });
                  }}
                />
              ))}
            </div>
          </div>

          <div className="options__group group__platform">
            <p className="options__title">Selecione a plataforma</p>
            <div className="options__container">
              {optionsPlatform.map(({ name, label, image }) => (
                <CardOption
                  key={name}
                  label={label}
                  isSelected={inputData.platformSelected === name}
                  image={image.file}
                  size={{ w: image.w, h: image.h }}
                  onSelect={() => {
                    onChangeInputData({ key: 'platformSelected', value: name });
                  }}
                />
              ))}
            </div>
          </div>
          <div className="options__group group__label">
            <p className="options__title">Selecione o ambiente</p>
            <div className="options__events">
              {optionsEnvironment.map(({ name }, index) => (
                <LabelEvent
                  key={index}
                  name={name}
                  isSelected={inputData.environmentSelected === name}
                  onSelect={() => {
                    onChangeInputData({
                      key: 'environmentSelected',
                      value: name,
                    });
                  }}
                />
              ))}
            </div>
          </div>

          <div className="options__group group__label">
            <p className="options__title">
              Selecione os eventos que devem existir na coleta informada
            </p>

            <div className="options__events">
              {optionsEvents.map(({ name }, index) => (
                <LabelEvent
                  key={index}
                  name={name}
                  isSelected={isSelectedEvent(name)}
                  onSelect={() => {
                    onChangeEventsSelected(name);
                  }}
                />
              ))}
            </div>
          </div>

          <div className="options__group group__input">
            <p className="options__title">TraceIds</p>
            <Information
              title={titleInformation}
              example={exampleInformation}
              isVisible={isVisibleInformation}
            />
            <form onSubmit={onSubmit}>
              <textarea
                name="input"
                placeholder="Adiciona as informações aqui"
                rows={10}
                className="options__input"
              />
              <button type="submit" className="options__submit">
                Buscar
              </button>
            </form>
          </div>
        </section>
      </section>
    </React.Fragment>
  );
}
