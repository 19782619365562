import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { HttpPickerSession } from './HttpPickerSession';

export const HttpPickerSessionField = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'HttpPickerSession',
    // @ts-ignore
    component: HttpPickerSession,
  }),
);
