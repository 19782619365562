import React, { useEffect } from 'react';

import { Button } from '@material-ui/core';

import {
  useEntityList,
  EntityKindPicker,
  EntityLifecyclePicker,
  EntityTagPicker,
  EntityListProvider,
  EntityOwnerPicker,
  UserListPicker,
  CatalogFilterLayout,
  EntityTypeFilter,
  EntityKindFilter,
} from '@backstage/plugin-catalog-react';

import { ItemCardGrid, EmptyState, Progress } from '@backstage/core-components';
import { ExploreTool } from '@backstage-community/plugin-explore-react';
import { ToolCard } from './ToolCard';

const namespace = 'default';

const ToolsList = () => {
  const { loading, entities, updateFilters } = useEntityList();

  // Filter only entities with kind = resource && type = tool
  useEffect(() => {
    updateFilters({
      kind: new EntityKindFilter('resource'),
      type: new EntityTypeFilter('tool'),
    });
  }, [updateFilters]);

  if (loading) {
    return <Progress />;
  }

  // create a list of ExploreTool components
  const tools = entities?.map(entity => {
    const list: ExploreTool = {
      title: entity.metadata.title ?? entity.metadata.name,
      description: entity.metadata.description,
      url: `/catalog/${namespace}/resource/${entity.metadata.name}`,
      image: entity.metadata?.annotations?.image ?? '',
      tags: entity.metadata.tags,
      lifecycle: entity.spec?.lifecycle?.toString() ?? '',
    };

    return list;
  });

  // if tool list is empty (due to some reason, e.g. select a wrong tag. see TODO below)
  if (!tools?.length) {
    return (
      <EmptyState
        title="No information to display"
        missing="content"
        description="Try to change the filters on the left side or contact DevPortal maintainer team"
        action={
          <Button variant="contained" color="primary" href="/catalog-import">
            Register an existing Tool
          </Button>
        }
      />
    );
  }

  return (
    <>
      <ItemCardGrid>
        {tools.map((tool, index) => (
          <ToolCard key={index} card={tool} />
        ))}
      </ItemCardGrid>
    </>
  );
};

export const ToolsExplore = () => {
  return (
    <EntityListProvider>
      <CatalogFilterLayout>
        <CatalogFilterLayout.Filters>
          <EntityKindPicker hidden />
          <UserListPicker initialFilter="all" />
          <EntityOwnerPicker />
          <EntityLifecyclePicker />
          <EntityTagPicker />
        </CatalogFilterLayout.Filters>
        <CatalogFilterLayout.Content>
          <ToolsList />
        </CatalogFilterLayout.Content>
      </CatalogFilterLayout>
    </EntityListProvider>
  );
};
