import React, { useState } from 'react';
import './style.css';
import { Sidebar } from './Partials/Sidebar';
import { Header } from './Partials/Header';
import { type DetailsEventsModalProps } from '../../_types/DetailsEventsModal';
import { BoxParams } from '../BoxParams';
import { createPortal } from 'react-dom';
import { Snackbar } from '@material-ui/core';

export function DetailsEventsModal({
  onClose,
  isShowDetails,
  data,
  eventSelected,
  onHandleSelectedEventDetails,
}: DetailsEventsModalProps): JSX.Element {
  const styleContainer = isShowDetails ? 'container__open' : 'container__close';
  const isSuccessEvent = eventSelected?.statusFrontTagify === 'success';
  const titleBoxRight = isSuccessEvent
    ? 'Parâmetros enviados'
    : 'Lista de erros';
  const rightParameters = isSuccessEvent
    ? eventSelected?.dataSend
    : {
        invalidData: eventSelected?.invalidData,
        invalidDataFrontRules: eventSelected?.invalidDataFrontRules,
      };
  const [isOpenToast, setIsOpenToast] = useState(false);

  function onCopyDataClipboard() {
    navigator.clipboard
      .writeText(JSON.stringify(rightParameters, null, 2))
      .then(() => {
        setIsOpenToast(true);
      })
      .catch(() => {});
  }

  return createPortal(
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpenToast}
        onClose={() => setIsOpenToast(false)}
        message="copiado para área de transferência"
        key="#"
      />
      <section className={`container__details ${styleContainer}`}>
        <div className="details__box">
          <Sidebar
            data={data}
            traceIdSelected={eventSelected?.traceId ?? ''}
            onHandleSelectedEventDetails={onHandleSelectedEventDetails}
          />
          <Header
            statusFront={eventSelected?.statusFrontTagify ?? ''}
            statusMS={eventSelected?.statusMS ?? ''}
            nameEvent={eventSelected?.dataParams?.name ?? ''}
            traceId={eventSelected?.traceId ?? ''}
            onClose={onClose}
            isSuccessEvent={isSuccessEvent}
            onCopyDataClipboard={onCopyDataClipboard}
          />
          <BoxParams
            title="Parâmetros recebidos"
            parameters={eventSelected?.dataParams}
            className="left__parameters"
          />
          <BoxParams
            title={titleBoxRight}
            parameters={rightParameters}
            className="right__parameters"
          />
        </div>
      </section>
    </>,
    document.body,
  );
}
