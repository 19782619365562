import React from 'react';

import { RELATION_OWNER_OF } from '@backstage/catalog-model';

import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import {
  CodeSnippet,
  InfoCard,
  Link,
  Progress,
  WarningPanel,
  ItemCardGrid,
} from '@backstage/core-components';

import { ExploreTool } from '@backstage-community/plugin-explore-react';

import { ToolCard } from '../toolsexplore/ToolCard';

import { strCmp } from '../../utils/conditions';

export const EntityHasToolsCard = () => {
  const namespace = 'default';

  // current group
  const { entity } = useEntity();

  // search only kind=Resource entities related to current entity (group)
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_OWNER_OF,
    kind: 'Resource',
  });

  // if group does not have resources ownership
  if (entities && !entities.length) {
    return null;
  }

  if (loading) {
    return (
      <InfoCard title="Tools">
        <Progress />
      </InfoCard>
    );
  }

  if (error) {
    return (
      <InfoCard title="Tools">
        <WarningPanel
          severity="error"
          title="Could not load Tools for this group"
          message={<CodeSnippet text={`${error}`} language="text" />}
        />
      </InfoCard>
    );
  }

  // extends ExploreTool type to add type property
  interface Tool extends ExploreTool {
    type: string;
  }

  // create list with entities under this group ownership
  const tools = entities
    ?.map(toolEntity => {
      const list: Tool = {
        title: toolEntity.metadata.name,
        description: toolEntity.metadata.description,
        url: `/catalog/${namespace}/resource/${toolEntity.metadata.name}`,
        image: toolEntity.metadata.annotations?.image ?? '',
        tags: toolEntity.metadata.tags,
        lifecycle: toolEntity.spec?.lifecycle?.toString() ?? '',
        type: toolEntity.spec?.type?.toString() ?? '',
      };

      return list;
    })
    .filter(e => strCmp(e.type, 'tool')); // filter just type=tool entities

  // if theres no Kind=Resource and Type=Tool
  if (!tools?.length) {
    return null;
  }

  const subheader = (
    <>
      <span>
        All tools under {entity?.metadata?.name} responsability.
        <Link to="/explore/tools">
          Click here to see the complete tools landscape
        </Link>
        .
      </span>
    </>
  );

  return (
    <InfoCard title="Tools Ownership" subheader={subheader}>
      <ItemCardGrid>
        {tools?.map((tool, index) => (
          <ToolCard key={index} card={tool} />
        ))}
      </ItemCardGrid>
    </InfoCard>
  );
};
