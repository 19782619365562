import React from 'react';
import FormControl from '@material-ui/core/FormControl';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { useEffect } from 'react';
import { useState } from 'react';

import { TextField } from '@material-ui/core';
import { configApiRef } from '@backstage/core-plugin-api';
import { useApi } from '@backstage/core-plugin-api';


export interface GitLabGroupPickerUiOptions {
  topLevelOnly?: boolean; 
  proxyName?:string;
}

export interface GitLabGroupPickerState {
  groupId?: string;
  groupName?: string;

  projectId?: string;
  projectName?: string;

  group?: any;
  project?: any;
}

export const GitLabGroupPicker = (
  props: FieldExtensionComponentProps<string, GitLabGroupPickerUiOptions>,
) => {
  const {
    onChange,
    schema: { title = 'Group', description = 'Pick some of listed groups' },
    state,
    required,
    uiSchema,
    rawErrors,
    formData,
    idSchema,
  } = props;

  // const topLevelOnly = uiSchema['ui:options']?.topLevelOnly || true;
  const proxyName = uiSchema['ui:options']?.proxyName || 'gitlabci';

  const configApi = useApi(configApiRef);
  const backendUrl = configApi.getString('backend.baseUrl');
  const groupsUrl = `${backendUrl}/api/proxy/${proxyName}/groups`

  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState<string[]>([]);
  const loading = open && groups.length === 0;

  useEffect(() => {
    let active = true;

    if(!loading){
      return undefined;
    }

    (async () => {
      const response = await fetch(groupsUrl);
      const groupsData = await response.json();

      if(active){
        setGroups(groupsData.map((g: { path: any; id: any; }) => `${g.path} [${g.id}]`));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, groupsUrl]);

  useEffect(() => {
    if(!open) {
      setGroups([]);
    }
  }, [open]);

  const onSelect = (_: any, value: string | null) => {
    onChange(value || '');
  };

  const {groupId, projectId} = state || {};

  return (
    <>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0 && !groupId}
      >
        <Autocomplete 
          id={idSchema?.$id}
          value={(formData as string) || ''}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          onChange={onSelect}
          options={groups || []}
          freeSolo={false}
          autoHighlight
          renderInput={(params) => (
            <TextField  
              {...params} 
              label={title} 
              margin="normal" 
              helperText={description} 
              variant="outlined" 
              required={required} 
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
      <FormControl margin="normal" required={required} error={rawErrors?.length > 0 && !projectId}> </FormControl>
    </>
  );
};
