import React from "react";
import "./style.css";
import type { SidebarProps } from "../../../../_types/DetailsEventsModal";
import { AlertIcon, SuccessIcon } from "../../../../assets/icons";

export function Sidebar({
  onHandleSelectedEventDetails,
  data,
  traceIdSelected,
}: SidebarProps): JSX.Element {
  return (
    <aside className="container__events">
      <p className="events__title">Todos os eventos</p>
      <div className="events__options">
        <div className="options__scroll">
          {data?.slice(0, 500).map((item, index) => {
            const isOptionSelected =
              traceIdSelected === item?.fullData?.traceId;
            const isSuccessStatus =
              item?.fullData?.statusFrontTagify === "success";
            const handleSelectButton = `option__${
              isOptionSelected ? "selected" : "normal"
            }`;
            const statusButton = `option__${
              isSuccessStatus ? "success" : "error"
            }`;
            return (
              <button
                key={index}
                disabled={isOptionSelected}
                className={`option__button ${handleSelectButton} ${statusButton}`}
                onClick={() => onHandleSelectedEventDetails(item.fullData)}
              >
                {isSuccessStatus ? <SuccessIcon /> : <AlertIcon />}

                {item?.fullData?.dataParams?.name}
              </button>
            );
          })}
        </div>
      </div>
    </aside>
  );
}
