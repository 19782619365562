import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '500px',
    margin: '-120px 20px -120px 20px' // it removes the top and bottom transparencies of the current logo image
  },
  st0: {
    fill: 'url(#SVGID_1_)'
  },
  st1: {
    fill: '#424242'
  },
  st2: {
    fill: '#5DB783'
  },
  st3: {
    fill: '#B0EFD4'
  },
  st4: {
    fill: '#73E7AB'
  },
  st5: {
    fill: '#4CCB88'
  },
  stop1: {
    stopColor: '#218D61'
  },
  stop2: {
    stopColor: '#24B66B'
  }
});

const LogoHome = () => {
  const classes = useStyles();

  return (
      <svg className={classes.svg} version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1200 1200">
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="21.3" y1="600.7927" x2="223.5" y2="600.7927">
          <stop  offset="0" className={classes.stop1}/>
          <stop  offset="1" className={classes.stop2}/>
        </linearGradient>
        <path className={classes.st0} d="M125.8,715.3c-5.7,0-11.4-1.6-16.4-4.7l-73.7-46.3c-9-5.7-14.4-15.4-14.4-26.1v-79.3c0-11,5.9-21.2,15.4-26.7
          l72.5-41.8c9.7-5.6,21.7-5.5,31.3,0.4l68.2,41.4c9.1,5.5,14.8,15.6,14.8,26.3v89.1c0,11.2-6.1,21.5-15.9,27l-67,36.9
          C136.1,714.1,130.9,715.3,125.8,715.3z"/>
        <path className={classes.st1} d="M530.7,564.2c6.3,0,11.7,1.5,16.1,4.5c4.5,3,7.7,7,9.7,12V540h18.9v100h-18.9v-15.7c-2,5-5.2,9.1-9.7,12.2
          c-4.4,3-9.8,4.5-16.1,4.5s-12-1.5-17-4.6s-9-7.5-11.9-13.2c-2.8-5.9-4.2-12.7-4.2-20.5s1.4-14.6,4.2-20.4
          c2.9-5.9,6.8-10.3,11.9-13.4C518.7,565.7,524.4,564.2,530.7,564.2z M536.7,580.8c-5.9,0-10.7,1.9-14.3,5.8
          c-3.6,3.9-5.4,9.2-5.4,15.9s1.8,12.1,5.4,15.9c3.6,3.8,8.4,5.7,14.3,5.7c5.8,0,10.5-1.9,14.2-5.8c3.8-4,5.7-9.2,5.7-15.8
          c0-6.7-1.9-11.9-5.7-15.8C547.2,582.8,542.4,580.8,536.7,580.8z M663.1,599.7c0,1.9-0.2,4.1-0.7,6.5h-54.9
          c0.3,6.7,2.1,11.6,5.4,14.7c3.3,3.2,7.5,4.7,12.4,4.7c4.4,0,8.1-1.1,10.9-3.2c3-2.2,4.9-5.1,5.7-8.8H662c-1,5.2-3.1,9.9-6.4,14.1
          c-3.2,4.1-7.4,7.4-12.6,9.7c-5,2.3-10.7,3.5-16.9,3.5c-7.3,0-13.8-1.5-19.5-4.6c-5.7-3.2-10.1-7.6-13.2-13.4
          c-3.2-5.8-4.7-12.6-4.7-20.4s1.6-14.6,4.7-20.4c3.2-5.9,7.6-10.3,13.2-13.4c5.7-3.1,12.2-4.6,19.5-4.6c7.4,0,13.8,1.5,19.3,4.6
          c5.6,3.1,9.9,7.3,12.8,12.7C661.6,586.8,663.1,592.9,663.1,599.7z M643.9,597.8c0.3-6.1-1.4-10.8-4.9-13.9
          c-3.4-3.2-7.7-4.7-12.7-4.7c-5.1,0-9.5,1.6-13,4.7c-3.5,3.2-5.5,7.8-5.8,13.9H643.9z"/>
        <path className={classes.st2} d="M709.2,621l18.9-55.8h20.3L720.7,640h-23.2l-27.7-74.9h20.4L709.2,621z"/>
        <path className={classes.st1} d="M826.4,564c6.3,0,12,1.5,17,4.6s9,7.5,11.8,13.4c2.9,5.8,4.3,12.6,4.3,20.4s-1.4,14.7-4.3,20.5
          c-2.8,5.8-6.7,10.2-11.8,13.2c-5,3.1-10.7,4.6-17,4.6s-11.7-1.5-16.2-4.5c-4.4-3.1-7.6-7.1-9.5-12.2v51.4h-19.1V565h19.1v15.5
          c1.9-5,5-9.1,9.5-12C814.7,565.5,820.1,564,826.4,564z M820.5,580.6c-5.8,0-10.5,2-14.3,5.9c-3.7,3.9-5.5,9.1-5.5,15.8
          c0,6.6,1.8,11.8,5.5,15.8c3.8,3.9,8.6,5.8,14.3,5.8c5.9,0,10.7-1.9,14.3-5.7c3.6-3.9,5.4-9.2,5.4-15.9s-1.8-12.1-5.4-15.9
          C831.2,582.6,826.4,580.6,820.5,580.6z M907.6,564c7.3,0,13.8,1.5,19.6,4.6c5.9,3.1,10.5,7.5,13.8,13.4c3.3,5.8,5,12.6,5,20.4
          s-1.7,14.6-5,20.4c-3.3,5.8-7.9,10.2-13.8,13.4c-5.8,3.1-12.3,4.6-19.6,4.6s-13.9-1.5-19.7-4.6c-5.8-3.2-10.3-7.6-13.6-13.4
          c-3.3-5.8-5-12.6-5-20.4s1.7-14.6,5-20.4c3.3-5.9,7.9-10.3,13.6-13.4C893.8,565.6,900.3,564,907.6,564z M907.6,580.5
          c-5.3,0-9.8,1.9-13.5,5.7c-3.7,3.7-5.5,9.1-5.5,16.2c0,7.1,1.8,12.6,5.5,16.4c3.7,3.7,8.2,5.5,13.5,5.5s9.8-1.8,13.5-5.5
          c3.7-3.8,5.5-9.2,5.5-16.4c0-7.1-1.8-12.5-5.5-16.2C917.5,582.4,912.9,580.5,907.6,580.5z M978.5,579.3c2.8-4.8,6.5-8.5,11.1-11.2
          c4.6-2.8,9.7-4.2,15.4-4.2v20.4h-5.5c-14,0-20.9,6.5-20.9,19.5v36.1h-19.1V565h19.1L978.5,579.3L978.5,579.3z M1055.6,623.5v16.4
          h-10c-8.4,0-14.8-2-19.3-6.1c-4.4-4.1-6.6-10.8-6.6-20.3V581h-10.4v-16h10.4v-18.5h19.1V565h16.8v16h-16.8v32.7
          c0,3.6,0.7,6.1,2.2,7.6c1.4,1.4,3.9,2.2,7.3,2.2H1055.6z M1098.6,564c6.3,0,11.7,1.5,16.2,4.5s7.7,7,9.6,12V565h18.9v74.9h-18.9
          v-15.7c-1.9,5-5.1,9.1-9.6,12.2c-4.5,3-9.9,4.5-16.2,4.5c-6.3,0-12-1.5-17-4.6s-9-7.5-11.9-13.2c-2.8-5.9-4.2-12.7-4.2-20.5
          s1.4-14.6,4.2-20.4c2.9-5.9,6.8-10.3,11.9-13.4C1086.6,565.6,1092.3,564,1098.6,564z M1104.6,580.6c-5.9,0-10.7,1.9-14.3,5.8
          c-3.6,3.9-5.4,9.2-5.4,15.9s1.8,12.1,5.4,15.9c3.6,3.8,8.4,5.7,14.3,5.7c5.8,0,10.5-1.9,14.2-5.8c3.8-4,5.7-9.2,5.7-15.8
          c0-6.7-1.9-11.9-5.7-15.8C1115.1,582.6,1110.3,580.6,1104.6,580.6z M1179.7,539.8v100h-19.1v-100H1179.7z"/>
        <path className={classes.st2} d="M328.5,585.8c0,9.8-8,17.8-17.8,17.8h-1.2c-3.3,0-5.9,2.7-5.9,5.9v11.8c0,3.3,2.7,5.9,5.9,5.9
          c15.4,0,29.7,4.6,41.5,12.3c1,0.6,1.9,1.3,2.9,2c11.9,7.9,26,13.4,41.3,15.5c4.6,0.6,9.4,1,14.2,1c30.2,0,54.7-24.4,54.7-54.5
          s-23.9-53.3-53.5-53.3H369c-3.3,0-5.9,2.7-5.9,5.9v50.6c2.5,1.4,4.8,3,7.2,4.6c1.2,1,2.5,1.9,3.8,2.8c1.1,0.8,2.3,1.5,3.4,2.2
          c0.4,0.3,0.8,0.5,1.3,0.7c3.6,1.6,7.7-0.9,8.1-4.9V612c0-0.1,0-0.2,0-0.4v-38.9h23.8c17.1,0,30.9,13.8,30.9,30.8s-14.4,32-32.1,32
          c-16.2,0-31-4.9-42.9-13.1c-0.7-0.5-1.5-1-2.2-1.6c-6.3-4.5-13.2-8.1-20.6-11c4.7-6,7.4-13.5,7.4-21.7v-2.4
          c0-19.6-16-35.5-35.6-35.5H274c-3.3,0-5.9,2.7-5.9,5.9v94.7c0,3.3,2.7,5.9,5.9,5.9h11.9c3.3,0,5.9-2.7,5.9-5.9v-78.1h23.8
          C322.7,572.7,328.5,578.6,328.5,585.8"/>
        <g>
          <path className={classes.st3} d="M184.3,564.1c-1.4-0.8-3-1.3-4.5-1.3c-1.4,0-2.8,0.3-4.1,1l-57.3,30.3c-2.9,1.5-4.7,4.5-4.7,7.8v63.8
            c0,3.1,1.6,5.9,4.3,7.5c2.7,1.6,5.9,1.7,8.7,0.2l57.3-30.3c2.9-1.5,4.7-4.5,4.7-7.8v-63.8C188.6,568.5,187,565.7,184.3,564.1z"/>
          <path className={classes.st4} d="M153.5,546.4c-1.4-0.8-3-1.3-4.5-1.3c-1.4,0-2.8,0.3-4.1,1l-57.3,30.3c-2.9,1.5-4.7,4.5-4.7,7.8v63.8
            c0,3.1,1.6,5.9,4.3,7.6c2.7,1.6,5.9,1.7,8.7,0.2l9.1-4.8l8.8-4.7v-44.5c0-3.3,1.8-6.3,4.7-7.8l39.4-20.8V554
            C157.8,550.9,156.2,548,153.5,546.4z"/>
          <path className={classes.st5} d="M122.6,528.8c-2.7-1.6-5.9-1.7-8.7-0.2l-57.3,30.3c-2.9,1.5-4.7,4.5-4.7,7.8v63.8c0,3.1,1.6,5.9,4.3,7.5
            c2.7,1.6,5.9,1.7,8.7,0.2l9-4.7l0,0l8.9-4.7v-44.5c0-3.3,1.8-6.3,4.7-7.8l39.4-20.8v-10v-9.3C126.9,533.2,125.3,530.4,122.6,528.8z
            "/>
        </g>
      </svg>
  );
};

export default LogoHome;