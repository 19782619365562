export interface FormStatusProps {
  children: React.ReactNode | JSX.Element;
}

export interface FormStatusActionsTypes {
  setPayloadStatus: (payload: any) => void;
}

export enum ActionsTypes {
  setPayloadStatus = 'SET_PAYLOAD_STATUS',
}

export interface StateType {
  input: any;
  flag: string;
  platform: string;
  events?: string[];
  environment: string;
  amountTraceIdInformed: number;
}

export interface UseFormStatusContextReturn {
  // dispatch: React.Dispatch<any>;
  dispatch: React.Dispatch<React.ReducerAction<any>>;
  stateFormStatus: StateType;
  actionsFormStatus: FormStatusActionsTypes;
}
