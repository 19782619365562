import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { GitLabGroupPicker } from './GitLabGroupPicker';

export const GitLabGroupPickerField = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GitLabGroupPicker',
    component: GitLabGroupPicker,
  }),
);
