import React from 'react';

const Register = () => {
    return (
      <>
        <iframe title="Formulário de Débitos Técnicos" style={{width: "100%", height: "100vh", border: "none"}} src="https://forms.office.com/r/Lf3fPZQjMc"/>
      </>
    );
};

const List = () => {
  return (
    <>
        <iframe title="Lista de Débitos Técnicos" style={{ width: "100%", height: "600px", border: "none" }} src="https://rdcombr.sharepoint.com/sites/Arquitetura4X/_layouts/15/Doc.aspx?sourcedoc={576a0be3-621e-4b2b-9cae-a7bbe21aac72}&action=embedview&wdAllowInteractivity=False&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True&ed1JS=true"/>
    </>
  );
};

export { Register, List };