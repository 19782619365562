import { ExploreLayout } from '@backstage-community/plugin-explore';
import { Home, Results } from './Pages';
import React from 'react';

import './styles/globals.css';
import './tagify.pages.css';

import { FormStatusProvider } from './components/Contexts';
import { useFormStatusContext } from './components/Contexts/FormStatus';

const Screens = () => {
  const { stateFormStatus } = useFormStatusContext();
  const shouldRenderResult = stateFormStatus !== null;

  return (
    <ExploreLayout
      title="Tagify"
      subtitle="Utilize esta página para interagir com a aplicação Tagify"
    >
      <ExploreLayout.Route path="home" title="Busca">
        <Home />
      </ExploreLayout.Route>
      {shouldRenderResult && (
        <ExploreLayout.Route path="results" title="Resultados">
          <Results />
        </ExploreLayout.Route>
      )}
    </ExploreLayout>
  );
};

export const TagifyPage = () => {
  return (
    <FormStatusProvider>
      <Screens />
    </FormStatusProvider>
  );
};

export const tagifyPage = <TagifyPage />;
