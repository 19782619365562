import { LinkButton } from '@backstage/core-components';
import { Divider } from '@material-ui/core';
import { Component } from './Form';
import React from 'react';

// const classes = useStyles();

/* const useStyles = makeStyles(theme => ({
  container: {
    boxSizing: 'border-box',
    padding: '20px 30px'
  },
})); */

interface Params {
  title: string
}

const headerText = "Muitos são os métodos ou modelos de classificação de serviços que podem compor um catálogo de <strong>COE</strong>. Aqui apresentaremos o modelo mais difundido que contribui para a geração de artefatos completos ao tratarmos de documentos de Arquitetura. Entre as capacidades do <strong>COE</strong> que é capaz de oferecer de maneira sucinta porém completa, uma gama com maior entregas esperadas em um catálogo de serviços, podendo ser alcançadas através de 5 serviços distintos, para saber mais sobre os mesmos por favor <strong>selecione um destes acima</strong>.<br>";
const requestURL = "/coe-services/register";
const buttonText = "Abrir Chamado";

const Inception = (params: Params) => {
    return (
      <>
        <p dangerouslySetInnerHTML={{ __html: headerText }} />
          <h1> {params.title} </h1>
          <p>Etapa primária para o início do mapeamento e documentação de um produto ou serviço.</p>
        <Divider/>
          <p> <b>Descrição</b> </p>
          <p>Primeiros passos para o mapeamento e documentação de um produto ou serviço.</p>
        <Divider/>
          <p> <b>Quando</b> </p>
          <p>Dúvida ou desconhecimento total do produto, seus responsáveis, suas entregas, seus consumidores...</p>
        <Divider/>
          <p> <b>DoR</b> </p>
          <p>Agenda com PO, LT e Consumidores.</p>
        <Divider/>
          <p> <b>DoD</b> </p>
          <p>Documentação mínima, que contemple o conhecimento e contato dos  seus responsáveis, suas entregas, seus consumidores,...</p>
        <Divider/>
        <ul>
          <li>Levantamento de aplicações</li>
          <li>Entrevistas stakeholders</li>
          <li>Levantamento de projetos em andamento</li>
        </ul>
        <LinkButton to={`${requestURL}?service=inception`} color="primary"> {buttonText} </LinkButton>
      </>
    );
};

const Discovery = (params: Params) => {
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: headerText }}/>
      <h1> {params.title} </h1>
      <p>Etapa destinada a geração de conhecimento para a correta documentação de produtos e serviços.</p>
      <Divider/>
        <p> <b>Descrição</b> </p>
        <p>Definição do negócio, suas capacidades, entrega de valor e dependências de serviços.</p>
      <Divider/>
        <p> <b>Quando</b> </p>
        <p>Não há total transparência sobre a definição do negócio, suas capacidades, entrega de valor e dependências de serviços.</p>
      <Divider/>
        <p> <b>DoR</b> </p>
        <p>Documentação mínima, que contemple o conhecimento e contato dos  seus responsáveis, suas entregas, seus consumidores...</p>
      <Divider/>
        <p> <b>DoD</b> </p>
        <p>Documentação validada sobre a definição do negócio, suas capacidades, entrega de valor e dependências de serviços.</p>
      <Divider/>
      <ul>
        <li>Realização de <i>Application Profiling</i></li>
        <li>Refinamento de produtos e aplicações</li>
        <li>Entrevista sobre AS-IS Tecnológico</li>
      </ul>
      <LinkButton to={`${requestURL}?service=discovery`} color="primary"> {buttonText} </LinkButton>
    </>
  );
};

const Analyse = (params: Params) => {
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: headerText }}/>
      <h1> {params.title} </h1>
      <p>Etapa de maior abrangência de serviços cotidianos de arquitetura, crucial para a geração de artefatos com alto valor agregado principalmente para o auxílio na tomada de decisão para evolução do negócio.</p>
      <Divider/>
        <p> <b>Descrição</b> </p>
        <p>Parecer sobre o produto/serviço, análise de tecnologias e padrões de arquitetura conforme o roadmap da companhia.</p>
      <Divider/>
        <p> <b>Quando</b> </p>
        <p>Há necessidade de tomar uma decisão sobre qual a melhor estratégia para manter um produto/serviço resiliente e/ou inovar ou modernizar.</p>
      <Divider/>
        <p> <b>DoR</b> </p>
        <p>Documentação validada sobre a definição do negócio, suas capacidades, entrega de valor e dependências de serviços.</p>
      <Divider/>
        <p> <b>DoD</b> </p>
        <p>Parecer sobre o produto, R's de modernização, oportunidades de negócio, análise <i>SWOT</i>, matriz <i>GUT</i>, recomendações de <i>Blueprints</i>.</p>
      <Divider/>
      <ul>
        <li>Análise de artefatos de <i>Discovery</i></li>
        <li>Análise de debito técnico</li>
        <li>Análise dos R's de modernização</li>
        <li>POCs</li>
      </ul>
      <LinkButton to={`${requestURL}?service=analyse`} color="primary"> {buttonText} </LinkButton>
    </>
  );
};

const AsIS = (params: Params) => {
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: headerText }}/>
      <h1> {params.title} </h1>
      <p>Etapa onde se é permitido conhecer o todo de um negócio, produto ou serviço.</p> 
      <Divider/>
        <p> <b>Descrição</b> </p>
        <p>Insumos e artefatos que contribuem para uma visão holística do produto ou serviço no presente.</p>
      <Divider/>
        <p> <b>Quando</b> </p>
        <p>Não há, em forma de diagramas, total transparência sobre a definição do negócio, suas capacidades, entrega de valor e dependências de serviços.</p>
      <Divider/>
        <p> <b>DoR</b> </p>
        <p>Documentação validada sobre a definição do negócio, suas capacidades, entrega de valor e dependências de serviços.</p>
      <Divider/>
        <p> <b>DoD</b> </p>
        <p>Diagrama As-Is em <i>C4 Model</i>, documentação auxiliar ( <b>Confluence</b> ) com detalhes de cada componente, dependências externas e internas, descrição da capacidade de negócio / serviço e entrega de valor.</p>
      <Divider/>
      <ul>
        <li>Planilha de manifesto do produto ou serviço</li>
        <li>Desenho C1 de parte da aplicação</li>
        <li>Diagrama C1 de toda a aplicação</li>
      </ul>
      <LinkButton to={`${requestURL}?service=asis`} color="primary"> {buttonText} </LinkButton>
    </>
  );
};

const ToBE = (params: Params) => {
  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: headerText }}/>
      <h1> {params.title} </h1>
      <p>Etapa onde as proposições de evolução são materializadas, permitindo-se contemplar o <b>TODO</b> - onde estamos e onde queremos e devemos estar, orientados pelo desejo de excelência!</p>
      <Divider/>
        <p> <b>Descrição</b> </p>
        <p>Insumos e artefatos que contribuem para uma visão holística do produto ou serviço no futuro.</p>
      <Divider/>
        <p> <b>Quando</b> </p>
        <p>Não há, quanto ao futuro, em forma de diagramas, total transparência sobre a definição do negócio, suas capacidades, entrega de valor e dependências de serviços.</p>
      <Divider/>
        <p> <b>DoR</b> </p>
        <p>Documentação validada sobre a definição do negocio, suas capacidades, entrega de valor e dependências de serviços, parecer sobre o produto, R's de modernização, oportunidades de negócio, análise <i>SWOT</i>, matriz <i>GUT</i>, recomendações de <i>Blueprints</i>.</p>
      <Divider/>
        <p> <b>DoD</b> </p>
        <p>Diagrama To-Be em <i>C4 Model</i>, documentação auxiliar ( <b>Confluence</b> ) com detalhes de cada componente, dependências externas e internas, descrição da capacidade de negócio / serviço e entrega de valor.</p>
      <Divider/>
      <ul>
      <li><i>Blueprint</i> de estratégia de inovação / modernização</li>
        <li>Proposta de modernização de parte da aplicação</li>
        <li>Proposta C1 de toda a aplicação</li>
      </ul>
      <LinkButton to={`${requestURL}?service=tobe`} color="primary"> {buttonText} </LinkButton>
    </>
  );
};

const Form = (params: Params) => {

  return (
    <>
      <p>Por favor, preencha os campos abaixo e pressione <strong>SOLICITAR</strong> para abrir um <strong>NOVO CHAMADO</strong> ao <strong>COE</strong> no <strong>JIRA</strong>.</p>
      <h1> {params.title} </h1>
      <Component />
    </>
  )
}

export { Inception, Discovery, Analyse, AsIS, ToBE, Form };