import { ExploreLayout } from '@backstage-community/plugin-explore';
import { Register, List } from './Pages';
import React from 'react';


export const TechDebtsPage = () => {
    return (
      <ExploreLayout title="Débitos Técnicos" subtitle="Utilize esta página para registrar ou consultar débitos técnicos">
        <ExploreLayout.Route path="list-tech-debts" title="Listar">
          <List />
        </ExploreLayout.Route>
        <ExploreLayout.Route path="register-tech-debt" title="Registrar">
          <Register />
        </ExploreLayout.Route>
      </ExploreLayout>
    );
};

export const techdebtsPage = <TechDebtsPage />;