/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: '170px',
    margin: '0px 0px 0px -4px' // it removes the left transparency of the current logo image
  },
  st0: {
    fill: 'url(#SVGID_1_)'
  },
  st1: {
    fill: '#FFFFFF'
  },
  st2: {
    fill: '#73E7AB'
  },
  st3: {
    fill: '#B0EFD4'
  },
  st4: {
    fill: '#4CCB88'
  },
  stop1: {
    stopColor: '#218D61'
  },
  stop2: {
    stopColor: '#24B66B'
  }
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1200 1200">
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="21.3" y1="600.7927" x2="223.5" y2="600.7927">
        <stop  offset="0" className={classes.stop1}/>
        <stop  offset="1" className={classes.stop2}/>
      </linearGradient>
      <path className={classes.st0} d="M125.8,715.3c-5.7,0-11.4-1.6-16.4-4.7l-73.7-46.3c-9-5.7-14.4-15.4-14.4-26.1v-79.3c0-11,5.9-21.2,15.4-26.7
        l72.5-41.8c9.7-5.6,21.7-5.5,31.3,0.4l68.2,41.4c9.1,5.5,14.8,15.6,14.8,26.3v89.1c0,11.2-6.1,21.5-15.9,27l-67,36.9
        C136.1,714.1,130.9,715.3,125.8,715.3z"/>
      <g>
        <path className={classes.st3} d="M184.3,564.1c-1.4-0.8-3-1.3-4.5-1.3c-1.4,0-2.8,0.3-4.1,1l-57.3,30.3c-2.9,1.5-4.7,4.5-4.7,7.8v63.8
          c0,3.1,1.6,5.9,4.3,7.5c2.7,1.6,5.9,1.7,8.7,0.2l57.3-30.3c2.9-1.5,4.7-4.5,4.7-7.8v-63.8C188.6,568.5,187,565.7,184.3,564.1z"/>
        <path className={classes.st2} d="M153.5,546.4c-1.4-0.8-3-1.3-4.5-1.3c-1.4,0-2.8,0.3-4.1,1l-57.3,30.3c-2.9,1.5-4.7,4.5-4.7,7.8v63.8
          c0,3.1,1.6,5.9,4.3,7.6c2.7,1.6,5.9,1.7,8.7,0.2l9.1-4.8l8.8-4.7v-44.5c0-3.3,1.8-6.3,4.7-7.8l39.4-20.8V554
          C157.8,550.9,156.2,548,153.5,546.4z"/>
        <path className={classes.st4} d="M122.6,528.8c-2.7-1.6-5.9-1.7-8.7-0.2l-57.3,30.3c-2.9,1.5-4.7,4.5-4.7,7.8v63.8c0,3.1,1.6,5.9,4.3,7.5
          c2.7,1.6,5.9,1.7,8.7,0.2l9-4.7l0,0l8.9-4.7v-44.5c0-3.3,1.8-6.3,4.7-7.8l39.4-20.8v-10v-9.3C126.9,533.2,125.3,530.4,122.6,528.8z
          "/>
      </g>
    </svg>
  );
};

export default LogoIcon;