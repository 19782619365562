import React from "react";
import { type FullLoadingProps } from "../../_types/FullLoading";
import "./style.css";

export function FullLoading({
  isLoading = false,
  title = "Analisando informações",
}: FullLoadingProps): JSX.Element {
  const styleContainer = isLoading ? "container__open" : "container__close";

  return (
    <div className={`container__loading ${styleContainer}`}>
      <div className="loading__box">
        <div className="hourglass" />
        <p className="box__title">{title}</p>
      </div>
    </div>
  );
}
