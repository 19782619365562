export interface CheckStatusEventsProps {
  traceIds: string;
  url: string;
}

export async function checkStatusEvents({
  traceIds,
  url,
}: CheckStatusEventsProps) {
  const raw = JSON.stringify({
    query: `
      query GetEventStatus(
        $traceId:String!
      ) {
        getTagifyEventsStatus(traceId: $traceId){
          events
        }
      }
    `,
    variables: {
      traceId: traceIds,
    },
  });

  try {
    const response = await fetch(url, {
      redirect: 'follow',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: raw,
    });
    const result = await response.text();
    return result;
  } catch (error) {
    throw error;
  }
}
