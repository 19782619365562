import React from "react";

import "./style.css";
import type { BoxParamsProps } from "../../_types/BoxParams";

export function BoxParams({
  title,
  parameters,
  className,
}: BoxParamsProps): JSX.Element {
  return (
    <section className={`container__params ${className}`}>
      <p className="param__title">{title}</p>
      <div className="param__box">
        <div className="box__scroll">
          <pre>{JSON.stringify(parameters, null, 2)}</pre>
        </div>
      </div>
    </section>
  );
}