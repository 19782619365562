import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder';
import { useEffect } from 'react';
import { useState } from 'react';
import { TextField } from '@material-ui/core';
import { catalogApiRef } from '@backstage/plugin-catalog-react'
import { useApi } from '@backstage/core-plugin-api';



export interface GitLabDomainPickerUiOptions {
  topLevelOnly?: boolean; 
  proxyName?:string;
}

export interface Metadata {
  metadata : GitLabDomainPickerState;
}
export interface GitLabDomainPickerState {
  uid?: string;
  name?: string;
  title?: string;
  description?: string;
  
}

export const GitLabDomainPicker = (
  props: FieldExtensionComponentProps<string, GitLabDomainPickerUiOptions>,
) => {
  const {
    onChange,
    schema: { title = 'Domain', description = 'Pick some of listed domain' },
    state,
    required,
    rawErrors,
    formData,
    idSchema,
  } = props;


  const catalogApi = useApi(catalogApiRef);
  const entityDomain = catalogApi.getEntities({
    filter: {kind: 'Domain',}
    ,}).then( data => {
      return data.items 
  })

  const [open, setOpen] = useState(false);
  const [domains, setDomains] = useState<string[]>([]);
  const loading = open && domains.length === 0;

  useEffect(() => {
    let active = true;

    if(!loading){
      return undefined;
    }

    (async () => {
      const domainsData = await entityDomain;
      if(active){
        setDomains(domainsData.map((g: Metadata) => `${g.metadata.name}`));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, entityDomain]);

  useEffect(() => {
    if(!open) {
      setDomains([]);
    }
  }, [open]);

  const onSelect = (_: any, value: string | null) => {
    onChange(value || '');
  };

  const {domainId, projectId} = state || {};

  return (
    <>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0 && !domainId}
      >
        <Autocomplete 
          id={idSchema?.$id}
          value={(formData as string) || ''}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          onChange={onSelect}
          options={domains || []}
          freeSolo={false}
          autoHighlight
          renderInput={(params) => (
            <TextField  
              {...params} 
              label={title} 
              margin="normal" 
              helperText={description} 
              variant="outlined" 
              required={required} 
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
      <FormControl margin="normal" required={required} error={rawErrors?.length > 0 && !projectId}> </FormControl>
    </>
  );
};
