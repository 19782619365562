import { Inception, Discovery, Analyse, AsIS, ToBE, Form } from './Pages';
import { ExploreLayout } from '@backstage-community/plugin-explore';
import React from 'react';


export const ServicesPage = () => {
    return (
      <>
        <ExploreLayout title="Catálogo de Serviços" subtitle="Conheça mais sobre o Catálogo de Serviços do COE">
          <ExploreLayout.Route path="inception" title="Inception">
            <Inception title="Inception"/>
          </ExploreLayout.Route>
          <ExploreLayout.Route path="discovery" title="Discovery">
            <Discovery title="Discovery"/>
          </ExploreLayout.Route>
          <ExploreLayout.Route path="analyse" title="Analyse">
            <Analyse title="Analyse"/>
          </ExploreLayout.Route>
          <ExploreLayout.Route path="as-is" title="As-Is">
            <AsIS title="As-Is"/>
          </ExploreLayout.Route>
          <ExploreLayout.Route path="to-be" title="To-Be">
            <ToBE title="To-Be"/>
          </ExploreLayout.Route>
          <ExploreLayout.Route path="register" title="Registrar Chamado">
            <Form title="Abertura de requisição no COE Arquitetura"/>
          </ExploreLayout.Route>
        </ExploreLayout>
      </>
    );
};

export const servicesPage = <ServicesPage />;