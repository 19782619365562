import React from "react";
import { type CardOptionProps } from "../../_types/CardOption";
import "./style.css";

export function CardOption({
  isSelected,
  image,
  size,
  label,
  onSelect,
}: CardOptionProps): JSX.Element {
  const statusCard = isSelected ? "card__selected" : "card__deselect";
  return (
    <button
      type="button"
      className={`container__card ${statusCard}`}
      onClick={onSelect}
    >
      <div className="card__image">
        <img
          src={image}
          alt={`Opção ${label}`}
          style={{
            width: size.w,
            height: size.h,
          }}
        />
      </div>
      <p className="card__title">{label}</p>
    </button>
  );
}
