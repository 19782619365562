import React from 'react';
import './style.css';
import type { InformationProps } from '../../_types/Information';

export function Information({
  title,
  example,
  isVisible,
}: InformationProps): JSX.Element {
  const isObject = typeof example === 'object';
  const classContainer = isVisible
    ? 'information--visible'
    : 'information--hidden';
  return (
    <div className={`information ${classContainer}`}>
      <h3 className="information__title">{title} </h3>
      {isObject ? (
        <pre className="information__pre">
          {JSON.stringify(example, null, 2)}
        </pre>
      ) : (
        <p className="information__pre">{example}</p>
      )}
    </div>
  );
}
