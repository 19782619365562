import {
  DomainExplorerContent,
  ExploreLayout,
  GroupsExplorerContent,
} from '@backstage-community/plugin-explore';
import React from 'react';

import { configApiRef, useApi } from '@backstage/core-plugin-api';

import { ToolsExplore } from '../toolsexplore/ToolsExplorePage';

export const ExplorePage = () => {
  const configApi = useApi(configApiRef);
  const organizationName =
    configApi.getOptionalString('organization.name') ?? 'Backstage';

  return (
    <ExploreLayout
      title={`Explore the ${organizationName} ecosystem`}
      subtitle="Discover solutions available in your ecosystem"
    >
      <ExploreLayout.Route path="domains" title="Domains">
        <DomainExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="groups" title="Groups">
        <GroupsExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="tools" title="Tools">
        <ToolsExplore />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
