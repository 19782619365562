import React from "react";
import "./style.css";
import type { CardNumberProps } from "../../_types/CardNumber";

export function CardNumber({
  status,
  label,
  value,
}: CardNumberProps): JSX.Element {
  const statusCard = `number__${status}`;

  return (
    <div className={`container__number ${statusCard}`}>
      <p className="number__value">{value}</p>
      <p className="number__label">{label}</p>
    </div>
  );
}
