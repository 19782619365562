import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export interface GetCurrentDatePickerOptions {
    readonly?: boolean;
  }

export const GetCurrentDatePicker = (
  props: FieldExtensionComponentProps<string, GetCurrentDatePickerOptions>,
) => {
  const [date, setDate] = useState<string>('');
  const { required, uiSchema, onChange } = props
  
  useEffect(() => {
    switch(uiSchema['ui:options']?.dateFormat as String) {
      case 'ISO':
          setDate(new Date().toISOString());
          break;
      case 'UTC':
          setDate(new Date().toUTCString());
          break;
      case 'EPOCH':
          setDate(Date.now().toString());
          break;
      default:
          setDate('error');
          break;
    }
  }, [uiSchema]);
  
  useEffect(() => {
    /* console.group(`:: DATE CHANGED ::`);
    console.log(`TYPEOF -- ${typeof date}`);
    console.log(`DATE -- ${date}`);
    console.groupEnd(); */
    onChange(date);
  }, [date, onChange]);

  return (
    
    <FormControl
      margin="normal"
      required={required}
    >
      <TextField
          id="outlined-basic"
          data-testid="currentDate"
          label={`Data Atual em formato ${uiSchema['ui:options']?.dateFormat}`}
          variant="outlined"
          value={date}
          disabled
      />
      
    </FormControl>
  );
};
