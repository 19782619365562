import React from 'react';
import FormControl from '@material-ui/core/FormControl';

import Autocomplete from '@material-ui/lab/Autocomplete';

import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

import { useEffect } from 'react';
import { useState } from 'react';

import { TextField } from '@material-ui/core';
import { configApiRef } from '@backstage/core-plugin-api';
import { useApi } from '@backstage/core-plugin-api';


export interface HttpPickerUiOptions {
  proxyName?:string;
  valueAttribute: string;
  uri: string;
}
export interface Namespace{
  id : number,
  name: string,
  path: string,
  kind: string,
  full_path: string,
  parent_id: number,
  avatar_url: string,
  web_url: string,
  members_count_with_descendants: number,
  billable_members_count: number,
  plan: string,
  trial_ends_on: string,
  trial: boolean
}

export interface EnumNamespace{
   path: string,
   id: string
}

export const HttpPickerKey = (
  props: FieldExtensionComponentProps<string, HttpPickerUiOptions>,
) => {
  const {
    onChange,
    schema: { title = 'Value', description = 'Pick some of listed values' },
    state,
    required,
    uiSchema,
    rawErrors,
    idSchema,
  } = props;

  const proxyName = uiSchema['ui:options']?.proxyName;
  const valueName = uiSchema['ui:options']?.valueAttribute || '';
  const uri = uiSchema['ui:options']?.uri;

  let requestUrl = `${uri}`
  const configApi = useApi(configApiRef);

  if (proxyName) {
    const backendUrl = configApi.getString('backend.baseUrl');
    requestUrl = `${backendUrl}/api/proxy/${proxyName}/${uri}`
  }

  const [data, setData] = useState<EnumNamespace[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [text, setText] = useState<string>('');
  const [open, setOpen] = useState(false);
  
  const loading = open && values.length === 0;

  function getID(namespace: string): string {
    const result = data.filter(item => item.path === namespace).shift()?.id;
    return result ? result : '';
  }

  // useEffect(() => console.log(`formData :: ${formData as string}`),[formData]);

  useEffect(() => {
    let active = true;

    if(!loading){
      return undefined;
    }

    (async () => {
      const response = await fetch(requestUrl);
      const result = await response.json();

      if(active){
        setValues(result.map((v: { [key: string]: any; }) => v[valueName]));
        setData(result.map((item: Namespace) => {
          const entry: EnumNamespace = {
            id: item.id.toString(),
            path: item.full_path
          }
          
          return entry;
        }));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, requestUrl, valueName]);

  useEffect(() => {
    if(!open) {
      setValues([]);
    }
  }, [open]);

  const onSelect = (_: any, value: string | null) => {
    if(!value) return;    

    const ID = getID(value!);
    onChange(ID || '');
    setText(value!);
  };

  const {value} = state || {};

  return (
    <>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0 && !value}
      >
        <Autocomplete 
          id={idSchema?.$id}
          value={text || ''}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          onChange={onSelect}
          options={values || []}
          freeSolo={false}
          autoHighlight
          renderInput={(params) => ( 
            <TextField 
              {...params} 
              label={title} 
              margin="normal" 
              helperText={description} 
              variant="outlined" 
              required={required} 
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
    </>
  );
};
