import type {
  OptionsEnvironmentProps,
  OptionsFlagsProps,
  OptionsPlatformProps,
} from '../_types/Home';

export const optionsFlags: OptionsFlagsProps = [
  {
    name: 'drogasil',
    label: 'Drogasil',
    image: {
      file: '../assets/images/logo_sil.png',
      w: 35,
      h: 35,
    },
  },
  {
    name: 'drogaraia',
    label: 'Drogaraia',
    image: {
      file: '../assets/images/logo_raia.png',
      w: 41,
      h: 35,
    },
  },
];

export const optionsPlatform: OptionsPlatformProps = [
  {
    name: 'android',
    label: 'Android',
    image: {
      file: '../assets/images/android.png',
      w: 35,
      h: 39,
    },
  },
  {
    name: 'ios',
    label: 'iOS',
    image: {
      file: '../assets/images/ios.png',
      w: 35,
      h: 43,
    },
  },
  {
    name: 'web',
    label: 'WEB',
    image: {
      file: '../assets/images/internet.png',
      w: 56,
      h: 45.16,
    },
  },
];

export const optionsEvents = [
  {
    name: 'view_item_list',
  },
  {
    name: 'select_item',
  },
  {
    name: 'add_to_cart',
  },
  {
    name: 'remove_from_cart',
  },
  {
    name: 'filter',
  },
  {
    name: 'sort',
  },
  {
    name: 'pagination',
  },
  {
    name: 'view_promotion',
  },
  {
    name: 'select_promotion',
  },
  {
    name: 'view_item',
  },
  {
    name: 'view_cart',
  },
  {
    name: 'add_payment_info',
  },
  {
    name: 'add_shipping_info',
  },
  {
    name: 'begin_checkout',
  },
  {
    name: 'purchase',
  },
  {
    name: 'search',
  },
  {
    name: 'secondary_search',
  },
  {
    name: 'select_content',
  },
  {
    name: 'add_to_wishlist',
  },
  {
    name: 'view_search_result',
  },
  {
    name: 'signup',
  },
  {
    name: 'refund',
  },
  {
    name: 'login',
  },
];

export const optionsEnvironment: OptionsEnvironmentProps = [
  {
    name: 'prod',
  },

  {
    name: 'stg',
  },

  {
    name: 'qa',
  },
];
